import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../services/data.service';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.css']
})
export class PaymentOptionsComponent implements OnInit {

  myRecaptcha: any;
  cartItems: any;
  cartPackageItems: any;
  isLoggedIn: boolean;
  user_id: any = localStorage.getItem('currentUserId') ? localStorage.getItem('currentUserId') : sessionStorage.getItem('currentUserId');
  path: any;
  images: any;
  packageImages: any;
  total: any;
  subTotal: any;
  tax: any;
  cartEmpty = false;
  userDetails: any;
  showSpinner: boolean;
  public payPalConfig?: PayPalConfig;

  constructor(private payment: DataService, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.isLoggedIn = true;
    }

    this.payment.getCartItems(this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.cartItems = data.success.cartItems;
          this.cartPackageItems = data.success.cartPackageItems;

          this.images = data.success.images;
          this.packageImages = data.success.packageImages;

          this.total = data.success.total;
          this.subTotal = data.success.subTotal;
          this.tax = data.success.tax;

          if (sessionStorage.getItem('promo_code') === null) {
            this.total = this.subTotal + this.tax;
            this.initConfig();
          } else {
            this.total = this.subTotal + this.tax - Number(sessionStorage.getItem('discount'));
            this.subTotal = this.subTotal - Number(sessionStorage.getItem('discount'));
            this.initConfig();
          }

          console.log(this.total);
          console.log(this.subTotal);
          console.log(this.tax);

          console.log(this.makeItems());
          console.log(this.getPackageIds());
          console.log(this.getImageIds());
          console.log(this.getItemStartDates());
          console.log(this.getItemEndDates());
        }

      },
      (error: any) => {
        console.log(error);
      }
    );

    this.payment.getUserDetails(this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.userDetails = data.success.user;
        }

      },
      (error: any) => {
        console.log(error);
      }
    );

  }

  makeItems() {

    const packageImage = this.cartPackageItems.map(item => {
      return {
        name: item.name,
        description: item.name,
        quantity: 1,
        price: item.price,
        tax: item.tax,
        currency: 'USD'
      };
    });
    const image = this.cartItems.map(item => {
      return {
        name: item.name,
        description: item.name,
        quantity: 1,
        price: item.price,
        tax: item.tax,
        currency: 'USD'
      };
    });

    let order = packageImage.concat(image);

    if (sessionStorage.getItem('promo_code') !== null) {
      const discount = {
        name: 'Discount',
        description: sessionStorage.getItem('promo_code'),
        quantity: 1,
        price: -Number(sessionStorage.getItem('discount')),
        tax: 0,
        currency: 'USD'
      };
      order = packageImage.concat(image , discount);
    }
    return order;
  }

  getPackageIds() {
    return this.cartPackageItems.map(item => {
      return {
        package_id: item.package_id,
      };
    });

  }

  getImageIds() {
    return this.cartItems.map(item => {
      return {
        image_id: item.image_id,
      };
    });

  }

  getItemStartDates() {
    const packageImage = this.cartPackageItems.map(item => {
      return {
        start_date: item.start_date,
      };
    });
    const image = this.cartItems.map(item => {
      return {
        start_date: item.start_date,
      };
    });

    const startDates = packageImage.concat(image);

    return startDates;
  }

  getItemEndDates() {
    const packageImage = this.cartPackageItems.map(item => {
      return {
        end_date: item.end_date,
      };
    });
    const image = this.cartItems.map(item => {
      return {
        end_date: item.end_date,
      };
    });

    const endDates = packageImage.concat(image);

    return endDates;
  }

  private initConfig() {
    this.payPalConfig = new PayPalConfig(
      PayPalIntegrationType.ClientSideREST,
      PayPalEnvironment.Sandbox,
      {
        commit: true,
        client: {
          sandbox:
            'AZDxjDScFpQtjWTOUtWKbyN_bDt4OgqaF4eYXlewfBP4-8aqX3PiV8e1GWU6liB2CUXlkA59kJXE7M6R'
        },
        button: {
          size: 'responsive',
          color: 'gold',
        },
        onAuthorize: (data, actions) => {
          console.log('Authorize');
          return of(undefined);
        },
        onPaymentComplete: (data, actions) => {
          console.log('OnPaymentComplete');
          const package_ids = this.getPackageIds();
          const image_ids = this.getImageIds();
          const price = this.subTotal + this.tax;
          const item_start_dates = this.getItemStartDates();
          const item_end_dates = this.getItemEndDates();
          this.payment.completeOrder(this.user_id, package_ids, image_ids, item_start_dates, item_end_dates, price).subscribe(
            (api: any) => {
              if (api.success) {
                console.log(api.success);
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
          this.payment.removeItemsFromCart(this.user_id).subscribe(
            (api: any) => {
              if (api.success) {
                console.log(api.success);
              }
            },
            (error: any) => {
              console.log(error);
            }
          );
          if (sessionStorage.getItem('promo_code') !== null) {
            this.payment.claimPromo(sessionStorage.getItem('promo_code'), this.user_id).subscribe(
              (api: any) => {
                if (api.success) {
                  console.log(api.success);
                }
              },
              (error: any) => {
                console.log(error);
              }
            );
          }
          this.router.navigate(['/orderComplete']);
          location.reload();
        },
        onCancel: (data, actions) => {
          location.reload();
          console.log('OnCancel');
        },
        onError: err => {
          console.log('OnError');
        },
        onClick: () => {
          console.log('onClick');
        },
        validate: (actions) => {
          console.log(actions);
        },
        experience: {
          noShipping: true,
          brandName: 'Backdrop Projections'
        },
        transactions: [
          {
            amount: {
              total: this.total,
              currency: 'USD',
              details: {
                subtotal: this.subTotal,
                tax: this.tax,
                shipping: 0.00,
                handling_fee: 0.00,
                shipping_discount: 0.00,
                insurance: 0.00,
              }
            },
            custom: 'Products',
            item_list: {
              items:
                this.makeItems(),
              shipping_address: {
                recipient_name: this.userDetails[0].name + ' ' + this.userDetails[0].last_name,
                line1: this.userDetails[0].billing_address,
                city: this.userDetails[0].city,
                country_code: this.userDetails[0].country,
                postal_code: this.userDetails[0].zip_code,
                phone: this.userDetails[0].tel,
                state: this.userDetails[0].state
              },
            },
          }
        ],
        note_to_payer: 'Contact us if you have troubles processing payment'
      }
    );
  }
}
