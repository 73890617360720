import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  baseUrl = environment.baseUrl;
  httpOptions: any;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token')
        : sessionStorage.getItem('token')}`
      })
    };
  }

  getRegisterDetails(name, last_name, tel, email , country , state , password, remember_me) {
    return this.http.post(this.baseUrl + 'api/website-api/register', {
      name,
      last_name,
      tel,
      email,
      country,
      state,
      password,
      remember_me
    }, this.httpOptions);

  }

  getLoginDetails(email, password, remember_me) {
    return this.http.post(this.baseUrl + 'api/website-api/login', {
      email,
      password,
      remember_me
    }, this.httpOptions);

  }

  forgotPassword(email) {
    return this.http.post(this.baseUrl + 'api/website-api/forgotPassword', {
      email
    }, this.httpOptions);

  }

  resetPassword(resetToken, email, password) {
    return this.http.post(this.baseUrl + 'api/website-api/passwordReset', {
      resetToken,
      email,
      password
    }, this.httpOptions);

  }

  createBackdrop(first_name, last_name, email, company_name, date_needed, image, comments, phone) {
    return this.http.post(this.baseUrl + 'api/website-api/createBackdrop', {
      first_name,
      last_name,
      email,
      company_name,
      date_needed,
      image,
      comments,
      phone
    }, this.httpOptions);

  }

  contactForm(first_name , last_name, email, phone, message) {
    return this.http.post(this.baseUrl + 'api/website-api/contactUs', {
      first_name,
      last_name,
      email,
      phone,
      message
    }, this.httpOptions);

  }

  browseBy(term_type_id , browseBy) {
    return this.http.get(this.baseUrl + 'api/website-api/browseBy',
      {
        headers: this.httpOptions,
        params: {term_type_id: term_type_id , browseBy: browseBy}
      }
    );
  }

  subCategory(id) {
    return this.http.get(this.baseUrl + 'api/website-api/subCategory',
      {
        headers: this.httpOptions,
        params: {id: id}
      }
    );
  }

  getProduct(id) {
    return this.http.get(this.baseUrl + 'api/website-api/productDetail',
      {
        headers: this.httpOptions,
        params: {id: id}
      }
    );
  }

  getPaymentStatus(user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/getPaymentStatus', {
      user_id,
    }, this.httpOptions);

  }



  getShowPackage(id) {
    return this.http.post(this.baseUrl + 'api/website-api/getShowPackage', {
      id,
    }, this.httpOptions);

  }

  search(q) {
    return this.http.get(this.baseUrl + 'api/website-api/search',
      {
        headers: this.httpOptions,
        params: {q: q}
      }
    );
  }


  addToFavorites(user_id, image_id) {
    return this.http.post(this.baseUrl + 'api/website-api/addToFavorites', {
      user_id,
      image_id
    }, this.httpOptions);

  }
  addShowPackageToFavorites(user_id, show_package_id) {
    return this.http.post(this.baseUrl + 'api/website-api/addShowPackageToFavorites', {
      user_id,
      show_package_id
    }, this.httpOptions);

  }

  removeFromFavorites(user_id, image_id) {
    return this.http.post(this.baseUrl + 'api/website-api/removeFromFavorites', {
      user_id,
      image_id
    }, this.httpOptions);

  }

  removeShowPackageFromFavorites(user_id, package_id) {
    return this.http.post(this.baseUrl + 'api/website-api/removeShowPackageFromFavorites', {
      user_id,
      package_id
    }, this.httpOptions);

  }

  getFavorites(user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/getFavorites', {
      user_id
    }, this.httpOptions);

  }


  categoryImage(term_type_id) {
    return this.http.post(this.baseUrl + 'api/website-api/getCategoryImage', {
      term_type_id
    }, this.httpOptions);

  }

  showThemeImage(term_type_id) {
    return this.http.post(this.baseUrl + 'api/website-api/getShowThemeImage', {
      term_type_id
    }, this.httpOptions);

  }

  curatedShowPackageImage() {
    return this.http.post(this.baseUrl + 'api/website-api/getCuratedShowPackageImage', {
    }, this.httpOptions);

  }

  addToCart(user_id, image_id, start_date, end_date, price, name) {
    return this.http.post(this.baseUrl + 'api/website-api/addToCart', {
      user_id,
      image_id,
      start_date,
      end_date,
      price,
      name
    }, this.httpOptions);

  }

  updateCart(user_id, image_id, start_date, end_date, price) {
    return this.http.post(this.baseUrl + 'api/website-api/updateCart', {
      user_id,
      image_id,
      start_date,
      end_date,
      price,
    }, this.httpOptions);

  }

  updatePackageCart(user_id, package_id, start_date, end_date, price) {
    return this.http.post(this.baseUrl + 'api/website-api/updatePackageCart', {
      user_id,
      package_id,
      start_date,
      end_date,
      price,
    }, this.httpOptions);

  }

  checkCart(image_id, user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/checkCart', {
      image_id,
      user_id
    }, this.httpOptions);

  }

  removeFromCart(image_id, user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/removeFromCart', {
      image_id,
      user_id
    }, this.httpOptions);

  }

  addPackageToCart(user_id, package_id, start_date, end_date, price, name) {
    return this.http.post(this.baseUrl + 'api/website-api/addPackageToCart', {
      user_id,
      package_id,
      start_date,
      end_date,
      price,
      name
    }, this.httpOptions);

  }

  checkCartPackage(package_id, user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/checkCartPackage', {
      package_id,
      user_id
    }, this.httpOptions);

  }

  removePackageFromCart(package_id, user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/removePackageFromCart', {
      package_id,
      user_id
    }, this.httpOptions);

  }

  removeItemsFromCart(user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/removeItemsFromCart', {
      user_id
    }, this.httpOptions);

  }

  getCartItems(user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/getCartItems', {
      user_id
    }, this.httpOptions);

  }

  countCart(user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/countCart', {
      user_id
    }, this.httpOptions);

  }

  addToNewsletter(user_id, name, last_name , email) {
    return this.http.post(this.baseUrl + 'api/website-api/addToNewsletter', {
      user_id,
      name,
      last_name,
      email
    }, this.httpOptions);

  }

  getBackdropPhotos(image_id) {
    return this.http.post(this.baseUrl + 'api/website-api/getBackdropPhotos', {
      image_id
    }, this.httpOptions);

  }

  getUserDetails(user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/getUserDetails', {
      user_id
    }, this.httpOptions);

  }

  updateUserDetails(user_id, name, last_name, country, city, state, billing_address, zip_code, company_name, tel , newsletter) {
    return this.http.post(this.baseUrl + 'api/website-api/updateUserDetails', {
      user_id,
      name,
      last_name,
      country,
      city,
      state,
      billing_address,
      zip_code,
      company_name,
      tel,
      newsletter
    }, this.httpOptions);

  }

  completeOrder(user_id, package_ids, image_ids, item_start_dates, item_end_dates, price) {
    return this.http.post(this.baseUrl + 'api/website-api/completeOrder', {
      user_id,
      package_ids,
      image_ids,
      item_start_dates,
      item_end_dates,
      price
    }, this.httpOptions);

  }

  processOrder(user_id ,  discount , promo_code_id , tax){
    return this.http.post(this.baseUrl + 'api/website-api/processOrder', {
      user_id,
      discount,
      promo_code_id,
      tax,
    }, this.httpOptions);
  }

  checkPromo(code, user_id) {
    return this.http.get(this.baseUrl + 'api/website-api/check-promo',
      {
        headers: this.httpOptions,
        params: {code: code , user_id: user_id}
      }
    );
  }

  claimPromo(code, user_id) {
    return this.http.get(this.baseUrl + 'api/website-api/claim-promo',
      {
        headers: this.httpOptions,
        params: {code: code , user_id: user_id}
      }
    );
  }

  passwordResetEmail(token) {
    return this.http.post(this.baseUrl + 'api/website-api/passwordResetEmail', {
     token,
    }, this.httpOptions);

  }

  getApp() {
    return this.http.post(this.baseUrl + 'api/website-api/getApp', {
    }, this.httpOptions);

  }

  checkChargeToken() {
    return this.http.post(this.baseUrl + 'api/website-api/chargeToken', {
    }, this.httpOptions);

  }

  checkUserStorageData(user_id) {
    return this.http.post(this.baseUrl + 'api/website-api/checkUserStorageData', {
      user_id,
    }, this.httpOptions);

  }

  getMaintenanceMode() {
    return this.http.post(this.baseUrl + 'api/website-api/getMaintenanceMode', {
    }, this.httpOptions);
  }
}
