import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { isDevMode } from '@angular/core';
import {local} from "../../../environments/local";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.css']
})
export class OrderCompleteComponent implements OnInit {

mac: any;
windows: any;
path: any;

  constructor(private router: Router , private order: DataService,private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Order Complete - Backdrop Projections");


      this.path = local.imageUrl + '/storage';


    this.order.getApp().subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.windows = data.success.windows;
          this.mac = data.success.mac;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );

  }

}
