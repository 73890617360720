import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from '../../services/data.service';
import { isDevMode } from '@angular/core';
import { local } from '../../../environments/local';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.css']
})
export class GalleryModalComponent implements OnInit {

  id: any = localStorage.getItem('image_id');
  backdropPhoto: any;
  backdropPhotoDescription: any;
  path: any;
  imagePath: any;
  productConfig: any;
  showPackages: boolean;
  backdropPackage: any;
  images: any;
  packageId = localStorage.getItem('package_id');
  packagedImages: any;
  showError: boolean;
  errorMessage: any = '';

  constructor(public dialog: MatDialog, private product: DataService, private router: Router, private route: ActivatedRoute) { }


  ngOnInit() {


      this.path = local.imageUrl + '/storage/images/backdrop_photos/';
      this.imagePath =  local.imageUrl + '/storage';


    if (localStorage.getItem('term_type_id') !== 'showPackages') {
    this.product.getBackdropPhotos(this.id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.backdropPhoto = data.success.backdropPhotos;
          this.backdropPhotoDescription = data.success.backdropPhotoDescription;
        }

      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }

  if (localStorage.getItem('term_type_id') === 'showPackages') {

    this.product.getProduct(sessionStorage.getItem('packageImageId')).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.showPackages = true;
          this.images = data.success.image;
          console.log(this.images);
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
        this.errorMessage = error.error.message;
      }
    );
  }


}
  slickInit(e) {
    console.log('slick initialized');
  }

}
