import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { isDevMode } from '@angular/core';
import { GalleryModalComponent } from '../gallery-modal/gallery-modal.component';
import { HandPaintedBackdropsComponent } from '../hand-painted-backdrops/hand-painted-backdrops.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PreviousRouteService } from '../../services/previous-route.service';
import { local } from '../../../environments/local';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  myRecaptcha: boolean;
  showError: boolean;
  errorMessage: any = '';
  showSuccess: boolean;
  showSpinner: boolean;
  title: any;
  termId: any = localStorage.getItem('term_type_id');
  termName: any = localStorage.getItem('termName');
  id: any;
  images: any;
  categoryName: any = localStorage.getItem('categoryName');
  recent: any;
  recentImages: any;
  relatedImages: any;
  isLoggedIn: boolean;
  liked = false;
  inCart: boolean;
  packageInCart: boolean;
  favoriteText: any = 'Add to favorites';
  user_id: any;
  favoriteImages: any;
  favoritePackages: any;
  numberOfDays: any = 0;
  start: any;
  end: any;
  total: any = 0;
  pricePerDay: any;
  path: any;
  pageLikes: any;
  cartValues: any;
  minDate = moment().format();
  date: Date;
  showPackages: boolean;
  packagedImages: any;
  packageId: any;
  package: any;
  backdropPhoto: any;
  backdropPhotoDescription: any;
  backdropPhotoDisable: boolean;
  update = false;
  updateCartImage: any;
  start_date: any;
  end_date: any;
  backdropsBy: any;
  backdropPackage: any;
  categoryId: any = localStorage.getItem('categoryId');
  priceDaily: any;
  additionalDays: any;
  relatedImagesLink: any;
  recentName: any;
  searchBreadcrumbs = false;
  favoriteBreadcrumbs = false;
  cartBreadcrumbs = false;
  relatedBreadcrumbs = false;
  recentBreadcrumbs = false;
  breadcrumbItem: any;
  categoryURL = 'categories';
  subCategoryURL = 'subCategory';
  duplicate = true;

  constructor(private product: DataService, private router: Router, public dialog: MatDialog
    , private previousRouteService: PreviousRouteService, private route: ActivatedRoute,private titleService:Title) { }

  openDialog($event, image_id): void {
    sessionStorage.setItem('packageImageId', image_id);
    const dialogRef = this.dialog.open(GalleryModalComponent, {
      width: '1250px',
      height: 'auto',
    });

    document.getElementById('close').onclick = function () {
      dialogRef.close();
    };

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openHandPaintedBackdrops(): void {
    const dialogRef = this.dialog.open(HandPaintedBackdropsComponent, {
      width: '1250px',
      height: '600px',
    });

    document.getElementById('close').onclick = function () {
      dialogRef.close();
    };

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit() {

    this.path = local.imageUrl + '/storage';

    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.isLoggedIn = true;
    }

    this.route.params.subscribe(params =>
      this.backdropsBy = params['browseBy']);

    this.route.params.subscribe(params =>
      this.packageId = params['packageId']);

    this.route.params.subscribe(params =>
      this.id = params['id']);

    this.route.params.subscribe(params =>
      this.backdropPackage = params['backdropPackage']);

    if (this.backdropPackage !== undefined) {
      localStorage.setItem('term_type_id', 'showPackages');
    }

    localStorage.setItem('categoryId', this.id);
    localStorage.setItem('image_id', this.id);
    if (this.termName !== null) {
      this.backdropsBy = this.termName.replace(/[_-]/g, ' ');
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.user_id = localStorage.getItem('currentUserId') ? localStorage.getItem('currentUserId') : sessionStorage.getItem('currentUserId');

    this.showSpinner = true;

    // Breadcrumbs when coming from the search component
    if (document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '') === '/search?q='+sessionStorage.getItem('q') &&
      this.previousRouteService.getPreviousUrl() !== '/cart' &&
      this.previousRouteService.getPreviousUrl() !== '/favorites' &&
      sessionStorage.getItem('breadCrumbImage') != 'relatedImages' &&
      sessionStorage.getItem('breadCrumbImage') != 'recentImages' &&
      sessionStorage.getItem('breadCrumbImage') != null) {
      this.searchBreadcrumbs = true;
      this.breadcrumbItem = sessionStorage.getItem('breadCrumbImage');
    } else {
      this.searchBreadcrumbs = false;
    }

    // Breadcrumbs when coming from the favorites component
    if (this.previousRouteService.getPreviousUrl() === '/favorites') {
      this.favoriteText = "Remove from favorites";
      this.favoriteBreadcrumbs = true;
      this.breadcrumbItem = sessionStorage.getItem('breadCrumbImage');
    } else {
      this.favoriteBreadcrumbs = false;
    }

    // Breadcrumbs when coming from the cart component
    if (this.previousRouteService.getPreviousUrl() === '/cart') {
      this.cartBreadcrumbs = true;
      this.breadcrumbItem = sessionStorage.getItem('breadCrumbImage');
    } else {
      this.cartBreadcrumbs = false;
    }

    // Breadcrumbs when clicking relatedImages
    if (sessionStorage.getItem('breadCrumbImage') == 'relatedImages') {
      this.relatedBreadcrumbs = true;
      this.breadcrumbItem = sessionStorage.getItem('breadCrumbImage');
    } else {
      this.relatedBreadcrumbs = false;
    }

    // Breadcrumbs when clicking recentImages
    if (sessionStorage.getItem('breadCrumbImage') == 'recentImages') {
      this.recentBreadcrumbs = true;
      this.breadcrumbItem = sessionStorage.getItem('breadCrumbImage');
    } else {
      this.recentBreadcrumbs = false;
    }
    // Breadcrumbs when coming from category or sub category
    const checkURL = this.previousRouteService.getPreviousUrl();
    if (checkURL.includes(this.categoryURL) === true || checkURL.includes(this.subCategoryURL) === true) {
      this.searchBreadcrumbs = false;
      this.favoriteBreadcrumbs = false;
      this.cartBreadcrumbs = false;
      this.relatedBreadcrumbs = false;
      this.recentBreadcrumbs = false;
    }

    switch (this.termId) {
      case '1': {
        this.title = 'Category';
        break;
      }
      case '2': {
        this.title = 'Show Theme';
        break;
      }
      case 'showPackages': {
        this.title = 'Curated Show Packages';
        break;
      }
      default: {
        this.title = '';
        break;
      }
    }

    if (this.previousRouteService.getPreviousUrl() === '/login' && sessionStorage.getItem('cartImageStart') === 'undefined') {
      sessionStorage.removeItem('cartImageId');
      sessionStorage.removeItem('cartImageName');
      sessionStorage.removeItem('cartImageTotal');
      sessionStorage.removeItem('cartImageStart');
      sessionStorage.removeItem('cartImageEnd');
    }


    // if (this.previousRouteService.getPreviousUrl() === '/login' &&  sessionStorage.getItem('cartImageStart') !== null ) {


    //   if (this.termId !== 'showPackages') {
    //     this.product.checkCart(Number(sessionStorage.getItem('cartImageId')), this.user_id).subscribe(
    //       (data: any) => {
    //         if (data.success) {
    //           console.log(data.success);
    //           if (data.success.cartItem.length === 0) {
    //             this.inCart = false;
    //           } else {
    //             this.inCart = true;
    //           }
    //         }
    //       },
    //       (error: any) => {
    //         console.log(error);
    //         this.inCart = false;
    //         this.router.navigate(['/error']);
    //       }
    //     );
    //     setTimeout(() => {
    //       if (!this.inCart) {
    //         this.product.addToCart(this.user_id, sessionStorage.getItem('cartImageId'),
    //           sessionStorage.getItem('cartImageStart'), sessionStorage.getItem('cartImageEnd'),
    //           Number(sessionStorage.getItem('cartImageTotal')), sessionStorage.getItem('cartImageName')).subscribe(
    //             (data: any) => {
    //               if (data.success) {
    //                 console.log(data.success);
    //                 this.inCart = true;
    //                 this.cartValues += 1;
    //               }
    //             },
    //             (error: any) => {
    //               console.log(error);
    //               this.router.navigate(['/error']);
    //             }
    //           );
    //         sessionStorage.removeItem('cartImageId');
    //         sessionStorage.removeItem('cartImageName');
    //         sessionStorage.removeItem('cartImageTotal');
    //         sessionStorage.removeItem('cartImageStart');
    //         sessionStorage.removeItem('cartImageEnd');
    //       } else {
    //         sessionStorage.removeItem('cartImageId');
    //         sessionStorage.removeItem('cartImageName');
    //         sessionStorage.removeItem('cartImageTotal');
    //         sessionStorage.removeItem('cartImageStart');
    //         sessionStorage.removeItem('cartImageEnd');
    //       }
    //     }, 1000);
    //   } else {
    //     this.product.checkCartPackage(Number(sessionStorage.getItem('cartImageId')), this.user_id).subscribe(
    //       (data: any) => {
    //         if (data.success) {
    //           console.log(data.success);
    //           if (data.success.cartItem.length === 0) {
    //             this.packageInCart = false;
    //           } else {
    //             this.packageInCart = true;
    //           }
    //         }
    //       },
    //       (error: any) => {
    //         console.log(error);
    //         this.packageInCart = false;
    //         this.router.navigate(['/error']);
    //       }
    //     );
    //     setTimeout(() => {
    //       if (!this.packageInCart) {
    //         this.product.addPackageToCart(this.user_id, sessionStorage.getItem('cartImageId'),
    //           sessionStorage.getItem('cartImageStart'), sessionStorage.getItem('cartImageEnd'),
    //           Number(sessionStorage.getItem('cartImageTotal')), sessionStorage.getItem('cartImageName')).subscribe(
    //             (data: any) => {
    //               if (data.success) {
    //                 console.log(data.success);
    //                 this.inCart = true;
    //                 this.cartValues += 1;
    //               }
    //             },
    //             (error: any) => {
    //               console.log(error);
    //               this.router.navigate(['/error']);
    //             }
    //           );
    //         sessionStorage.removeItem('cartImageId');
    //         sessionStorage.removeItem('cartImageName');
    //         sessionStorage.removeItem('cartImageTotal');
    //         sessionStorage.removeItem('cartImageStart');
    //         sessionStorage.removeItem('cartImageEnd');
    //       } else {
    //         sessionStorage.removeItem('cartImageId');
    //         sessionStorage.removeItem('cartImageName');
    //         sessionStorage.removeItem('cartImageTotal');
    //         sessionStorage.removeItem('cartImageStart');
    //         sessionStorage.removeItem('cartImageEnd');
    //       }
    //     }, 1000);
    //   }

    // }



    if (this.previousRouteService.getPreviousUrl() !== '/cart') {
      sessionStorage.removeItem('cartImageUpdate');
    }

    if (sessionStorage.getItem('cartImageUpdate')) {
      this.update = true;
      if (this.termId !== 'showPackages') {
        this.product.checkCart(this.id, this.user_id).subscribe(
          (data: any) => {
            if (data.success) {
              console.log(data.success);
              this.updateCartImage = data.success.cartItem;
              this.total = this.updateCartImage[0].price;
              this.start_date = this.updateCartImage[0].start_date;
              this.end_date = this.updateCartImage[0].end_date;


              this.start = moment(this.start_date).format('MM/DD/YYYY');
              this.start_date = new Date(this.start);


              this.end = moment(this.end_date).format('MM/DD/YYYY');
              this.end_date = new Date(this.end);

              const end_date = moment(this.end_date);
              const a = moment(this.start_date);
              const b = moment(end_date);
              this.numberOfDays = b.diff(a, 'days');
            }
          },
          (error: any) => {
            console.log(error);
            this.router.navigate(['/error']);
          }
        );
      }
      if (this.termId === 'showPackages') {
        this.product.checkCartPackage(this.packageId, this.user_id).subscribe(
          (data: any) => {
            if (data.success) {
              console.log(data.success);
              this.updateCartImage = data.success.cartItem;
              this.total = this.updateCartImage[0].price;
              this.start_date = this.updateCartImage[0].start_date;
              this.end_date = this.updateCartImage[0].end_date;

              this.start = moment(this.start_date).format('MM/DD/YYYY');
              this.start_date = new Date(this.start);


              this.end = moment(this.end_date).format('MM/DD/YYYY');
              this.end_date = new Date(this.end);

              const end_date = moment(this.end_date);
              const a = moment(this.start_date);
              const b = moment(end_date);
              this.numberOfDays = b.diff(a, 'days');
            }
          },
          (error: any) => {
            console.log(error);
            this.packageInCart = false;
            this.router.navigate(['/error']);
          }
        );
      }
    }

    this.product.checkCart(this.id, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          if (data.success.cartItem.length === 0) {
            this.inCart = false;
          } else {
            this.inCart = true;
          }
        }

      },
      (error: any) => {
        console.log(error);
        this.inCart = false;
        this.router.navigate(['/error']);
      }
    );

    this.product.checkCartPackage(this.packageId, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          if (data.success.cartItem.length === 0) {
            this.packageInCart = false;
          } else {
            this.packageInCart = true;
          }
        }

      },
      (error: any) => {
        console.log(error);
        this.packageInCart = false;
        this.router.navigate(['/error']);
      }
    );

    this.product.getBackdropPhotos(this.id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.backdropPhotoDisable = false;
          this.backdropPhoto = data.success.backdropPhotos;
          this.backdropPhotoDescription = data.success.backdropPhotoDescription;
        }

      },
      (error: any) => {
        console.log(error);
        this.backdropPhotoDisable = true;
      }
    );

    if (this.packageId) {
      this.product.getShowPackage(this.packageId).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.showSpinner = false;
            this.showPackages = true;
            this.package = data.success.package;

            if(this.package[0].price === null || this.package[0].price === "" || this.package[0].price === undefined) {
              this.package[0].price = data.success.prices[0].show_packages
            }

            this.packagedImages = data.success.images;
            this.titleService.setTitle(this.package[0].name+" - Backdrop Projections");
            this.relatedImages = data.success.related;
            this.relatedImagesLink = data.success.relatedLinks;
            for (let i = 0; i < this.relatedImagesLink.length; i++) {
              this.relatedImagesLink[i].name = this.relatedImagesLink[i]['name'].replace(/[' ']/g, '-');
            }
            this.recent = sessionStorage.getItem('recentImages');
            this.recent = this.recent ? JSON.parse(this.recent) : [];
            this.recent.unshift(this.packagedImages);
            sessionStorage.setItem('recentImages', JSON.stringify(this.recent));
            this.recentImages = JSON.parse(sessionStorage.getItem('recentImages'));
            this.recentName = JSON.parse(sessionStorage.getItem('recentImages'));

            for (let i = 0; i < this.recentImages.length; i++) {
              this.recentImages[i][0].name = this.recentImages[i][0]['name'].replace(/[' ']/g, '-');
            }
            console.log(this.recentImages);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
          this.errorMessage = error.error.message;
          this.showError = true;
        }
      );
    }

    if (!this.packageId) {
      this.product.getProduct(this.id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.showSpinner = false;
            this.images = data.success.image;

            if(this.images[0].price === null && this.images[0].type === 'image') {
              this.images[0].price = data.success.prices[0].images
            }

            if(this.images[0].price === null && this.images[0].type === 'video' || this.images[0].type === 'gif') {
              this.images[0].price = data.success.prices[0].animations
            }

            this.titleService.setTitle(this.images[0].name+" - Backdrop Projections");

            if (document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '') === '/search') {
              this.categoryName = localStorage.getItem('categoryName');
              localStorage.setItem('subCategory_id', data.success.categoryId);
            } else {
              this.categoryName = localStorage.getItem('categoryName');
            }
            this.relatedImages = data.success.related;
            this.relatedImagesLink = data.success.relatedLinks;
            if (data.success.related !== undefined) {
            for (let i = 0; i < this.relatedImagesLink.length; i++) {
              this.relatedImagesLink[i].name = this.relatedImagesLink[i].name.replace(/[' ']/g, '-');
            }
          }
            this.recent = sessionStorage.getItem('recentImages');
            this.recent = this.recent ? JSON.parse(this.recent) : [];

            if (this.recent.length == 0) {
              this.recent.unshift(this.images);
            }

            for (let j = 0; j < this.recent.length; j++) {
              if (this.recent[j][0].id !== this.images[0].id) {
               this.duplicate = false;
              } else {
                this.duplicate = true;
                break;
              }

            }
            // alert(this.duplicate);
            if (this.duplicate === false) {
              this.recent.unshift(this.images);
            }
            sessionStorage.setItem('recentImages', JSON.stringify(this.recent));
            this.recentImages = JSON.parse(sessionStorage.getItem('recentImages'));
            this.recentName = JSON.parse(sessionStorage.getItem('recentImages'));
            for (let i = 0; i < this.recentImages.length; i++) {
              this.recentImages[i][0].name = this.recentImages[i][0]['name'].replace(/[' ']/g, '-');
            }
            console.log(this.recentImages);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
          this.errorMessage = error.error.message;
          this.showError = true;
        }
      );
    }
    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.product.getFavorites(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.favoriteImages = data.success.favorites;
            this.favoritePackages = data.success.packageID;
            if (this.termId !== 'showPackages') {
              for (let i = 0; i < this.favoriteImages.length; ++i) {
                console.log(this.favoriteImages);
                const imageLiked = this.favoriteImages[i];
                if (imageLiked.id == this.id) {
                  this.liked = true;
                  console.log('already liked');
                  break;
                }
              }
            }
            if (this.termId === 'showPackages') {
              for (let i = 0; i < this.favoritePackages.length; ++i) {
                const packageLiked = this.favoritePackages[i];
                if (packageLiked == this.packageId) {
                  this.liked = true;
                  console.log('already liked');
                  break;
                }
              }
            }
            this.pageLikes = this.favoriteImages.length + this.favoritePackages.length;
            if (this.liked === true && this.previousRouteService.getPreviousUrl() !== '/favorites') {
              this.favoriteText = 'Go To Favorites';
              this.pageLikes = this.favoriteImages.length + this.favoritePackages.length;
            }
            console.log(this.favoriteText);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    }

    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.product.countCart(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.cartValues = data.success.cartItems;
            console.log(this.cartValues);
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    } else {
      this.cartValues = 0;
    }
  }

   ngAfterViewInit(): void {
    try {
      setTimeout(() => {
        if( sessionStorage.getItem('cartImageUpdate') && localStorage.getItem('term_type_id') === '2') {
        document.getElementById('updateImage').scrollIntoView({behavior: "smooth", block: "end", inline: "start"});
        }
        if( sessionStorage.getItem('cartImageUpdate') && localStorage.getItem('term_type_id') === 'showPackages') {
        document.getElementById('updatePackage').scrollIntoView({behavior: "smooth", block: "end", inline: "start"});
        }
      }, 1000)
    } catch (e) { }
  }

  public loadImage(event, item) {
    sessionStorage.removeItem('cartImageUpdate');
    // localStorage.setItem('term_type_id', '2');
    this.showPackages = false;
    this.liked = false;
    this.favoriteText = 'Add to favorites';
    // localStorage.setItem('image_id', item);

    this.product.checkCart(item, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          if (data.success.cartItem.length === 0) {
            this.inCart = false;
          } else {
            this.inCart = true;
          }
        }

      },
      (error: any) => {
        console.log(error);
        this.inCart = false;
        this.router.navigate(['/error']);
      }
    );

    this.product.checkCartPackage(this.packageId, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          if (data.success.cartItem.length === 0) {
            this.packageInCart = false;
          } else {
            this.packageInCart = true;
          }
        }

      },
      (error: any) => {
        console.log(error);
        this.inCart = false;
        this.router.navigate(['/error']);
      }
    );
    this.product.getProduct(item).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.showSpinner = false;
          this.images = data.success.image;
          this.relatedImages = data.success.related;
          this.categoryName = data.success.category;
          localStorage.setItem('subCategory_id', data.success.categoryId);
          this.recent = sessionStorage.getItem('recentImages');
          this.recent = this.recent ? JSON.parse(this.recent) : [];
          this.recent.unshift(this.images);
          sessionStorage.setItem('recentImages', JSON.stringify(this.recent));
          this.recentImages = JSON.parse(sessionStorage.getItem('recentImages'));
          console.log(this.recentImages);

          if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
            this.product.getFavorites(this.user_id).subscribe(
              (imageData: any) => {
                if (data.success) {
                  console.log(imageData.success);
                  this.favoriteImages = imageData.success.favorites;
                  for (let i = 0; i < this.favoriteImages.length; ++i) {
                    const imageLiked = this.favoriteImages[i];
                    if (imageLiked.id == item) {
                      this.liked = true;
                      this.pageLikes = this.favoriteImages.length + this.favoritePackages.length;
                      console.log('already liked');
                      break;
                    }
                  }
                  if (this.liked === true && this.previousRouteService.getPreviousUrl() !== '/favorites') {
                    this.favoriteText = 'Go To Favorites';
                    // this.pageLikes = this.favoriteImages.length;
                  }
                  console.log(this.favoriteText);
                }
              },
              (error: any) => {
                console.log(error);
                this.router.navigate(['/error']);
              }
            );
          }
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
        this.errorMessage = error.error.message;
        this.showError = true;
      }
    );
    // this.router.navigate(['/productDetails']);
    window.scrollTo(0, 0);

    this.product.getBackdropPhotos(item).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.backdropPhotoDisable = false;
          this.backdropPhoto = data.success.backdropPhotos;
          this.backdropPhotoDescription = data.success.backdropPhotoDescription;

        }
      },
      (error: any) => {
        console.log(error);
        this.backdropPhotoDisable = true;
      }
    );
  }

  public redirectCart(event, imageId, imageName, imageTotal, imageStart, imageEnd) {
    // alert('add to cart');
    sessionStorage.setItem('cart' , 'cart');
    sessionStorage.setItem('cartImageId', imageId);
    sessionStorage.setItem('cartImageName', imageName);
    sessionStorage.setItem('cartImageTotal', imageTotal);
    sessionStorage.setItem('cartImageStart', imageStart);
    sessionStorage.setItem('cartImageEnd', imageEnd);

    this.router.navigate(['/login']);
  }

  public redirectFavorites(event, imageId) {
    // alert('add to favorites');
    sessionStorage.setItem('favorites' , 'favorites');
    sessionStorage.setItem('favoriteImageId' , imageId);
    this.router.navigate(['/login']);
  }

  public addFavorite(event, id) {
    if (this.favoriteText === 'Go To Favorites') {
      this.router.navigate(['/favorites']);

    }
    this.liked = true;
    if (this.favoriteText === 'Remove from favorites') {
      this.product.removeFromFavorites(this.user_id, id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.pageLikes -= 1;
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
      // this.router.navigateByUrl('/header', {skipLocationChange: true }).then(() =>  this.router.navigate(['/productDetails']));
      this.favoriteText = 'Add to favorites';
      this.liked = false;
      return;
    }

    if (this.liked === true) {
      this.favoriteText = 'Go To Favorites';
      this.product.addToFavorites(this.user_id, id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.pageLikes += 1;
            // this.pageLikes += 1;
            // this.router.navigateByUrl('/header', {skipLocationChange: true }).then(() =>  this.router.navigate(['/productDetails']));
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    }
  }

  public addShowPackageToFavorites(event, id) {
    if (this.favoriteText === 'Go To Favorites') {
      this.router.navigate(['/favorites']);
      return;
    }
    this.liked = true;
    if (this.favoriteText === 'Remove from favorites') {
      this.product.removeShowPackageFromFavorites(this.user_id, id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.pageLikes -= 1;
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
      // this.router.navigateByUrl('/header', {skipLocationChange: true }).then(() =>  this.router.navigate(['/productDetails']));
      this.favoriteText = 'Add to favorites';
      this.liked = false;
      return;
    }

    if (this.liked === true) {
      this.favoriteText = 'Go To Favorites';
      this.product.addShowPackageToFavorites(this.user_id, id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            this.pageLikes += 1;
            // this.pageLikes += 1;
            // this.router.navigateByUrl('/header', {skipLocationChange: true }).then(() =>  this.router.navigate(['/productDetails']));
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }
      );
    }
  }
  getStartDate($event, start_date, price) {
    this.start = moment(start_date).format('MM/DD/YYYY');
    start_date = new Date(this.start);
    start_date.setDate(start_date.getDate() + 7);
    this.date = start_date;
    this.end_date = this.date
    this.numberOfDays = 7;

    if (this.numberOfDays <= 7) {
      this.total = price;
      this.end = moment(this.date).format('MM/DD/YYYY');
    }
  }
  getEndDate($event, end_date, price, start_date, imageType) {
    this.end = moment(end_date);
    const a = moment(start_date);
    const b = moment(this.end);
    this.numberOfDays = b.diff(a, 'days');

    if (this.numberOfDays <= 7) {
      this.total = price;
    }
    if (this.numberOfDays > 7) {

      if (imageType === 'image') {
        this.pricePerDay = 3.15;
      }
      if (imageType === 'video' || imageType === 'gif') {
        this.pricePerDay = 5.15;
      }
      if (!imageType) {
        this.pricePerDay = 25;
      }
      this.additionalDays = this.numberOfDays;
      this.additionalDays = this.additionalDays - 7;
      this.priceDaily = (this.pricePerDay * this.additionalDays).toFixed(2);

      this.total = Number(price) + Number(this.priceDaily);

    }
    if (this.numberOfDays > 7) {
      this.additionalDays = this.numberOfDays;
      this.additionalDays = this.additionalDays - 7;
      this.priceDaily = (this.pricePerDay * this.additionalDays).toFixed(2);
    }
    this.end = moment(end_date).format('MM/DD/YYYY');
  }

  addToCart($event, image_id, image_name, price, start_date, end_date) {
    this.product.addToCart(this.user_id, image_id, start_date, end_date, price, image_name).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.inCart = true;
          this.cartValues += 1;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }

  updateCart($event, image_id, price, start_date, end_date) {
    this.product.updateCart(this.user_id, image_id, start_date, end_date, price).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          sessionStorage.removeItem('cartImageUpdate');
          this.router.navigate(['/cart']);
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }
  updatePackageCart($event, package_id, price, start_date, end_date) {
    this.product.updatePackageCart(this.user_id, package_id, start_date, end_date, price).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          sessionStorage.removeItem('cartImageUpdate');
          this.router.navigate(['/cart']);
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }

  removeFromCart($event, image_id) {
    this.product.removeFromCart(image_id, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.inCart = false;
          this.cartValues -= 1;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }

  addPackageToCart($event, image_id, image_name, price, start_date, end_date) {
    this.product.addPackageToCart(this.user_id, image_id, start_date, end_date, price, image_name).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.packageInCart = true;
          this.cartValues += 1;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }

  removePackageFromCart($event, image_id) {
    this.product.removePackageFromCart(image_id, this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.packageInCart = false;
          this.cartValues -= 1;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  productConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
  };
}
