import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CreateBackdropComponent } from './components/create-backdrop/create-backdrop.component';
import { OrderCompleteComponent } from './components/order-complete/order-complete.component';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { ContactComponent } from './components/contact/contact.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CartComponent } from './components/cart/cart.component';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreviousRouteService } from './services/previous-route.service';
import { NgxPayPalModule } from 'ngx-paypal';
import { GtagModule } from 'angular-gtag';
// import { MatMenuModule } from '@angular/material/menu';
import {
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatExpansionModule,
} from '@angular/material';
import { SearchComponent } from './components/search/search.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { GalleryModalComponent } from './components/gallery-modal/gallery-modal.component';
import { PaymentOptionsComponent } from './components/payment-options/payment-options.component';
import { HandPaintedBackdropsComponent } from './components/hand-painted-backdrops/hand-painted-backdrops.component';
import { FaqComponent } from './components/faq/faq.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { SignupSuccessfulComponent } from './components/signup-successful/signup-successful.component';
import { ContactThankYouComponent } from './components/contact-thank-you/contact-thank-you.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ErrorComponent } from './components/error/error.component';
import { FormSubmittedComponent } from './components/form-submitted/form-submitted.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceComponent } from './components/maintenance/maintenance.component'
import { local } from '../environments/local';
// import {MatFormFieldModule} from '@angular/material/form-field';
// import {MatDatepickerModule} from '@angular/material/datepicker';
const routes: Routes = [];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    CheckoutComponent,
    CreateBackdropComponent,
    OrderCompleteComponent,
    DownloadAppComponent,
    ContactComponent,
    CategoriesComponent,
    CartComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SubcategoryComponent,
    ProductDetailsComponent,
    FavoritesComponent,
    SearchComponent,
    GalleryModalComponent,
    PaymentOptionsComponent,
    HandPaintedBackdropsComponent,
    FaqComponent,
    AboutUsComponent,
    TermsConditionsComponent,
    SignupSuccessfulComponent,
    ContactThankYouComponent,
    NotFoundComponent,
    ErrorComponent,
    FormSubmittedComponent,
    LoadingComponent,
    PrivacyPolicyComponent,
    MaintenanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    SlickCarouselModule,
    SweetAlert2Module.forRoot(),
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    NgxPayPalModule,
    MatExpansionModule,
    NgxPaginationModule,
    GtagModule.forRoot({ trackingId: 'UA-140860170-1', trackPageviews: true }),
    ScrollToModule.forRoot(),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
    // PRODUCTION
    RecaptchaModule.forRoot({
      siteKey: local.recaptcha,
  }),
  // DEV
  // RecaptchaModule.forRoot({
  //       siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  //   }),
    BrowserAnimationsModule,
  ],
  providers: [CookieService ,  PreviousRouteService],
  bootstrap: [AppComponent],
  entryComponents: [GalleryModalComponent , PaymentOptionsComponent , HandPaintedBackdropsComponent]
})
export class AppModule { }
