import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { isDevMode } from '@angular/core';
import { PaymentOptionsComponent } from '../payment-options/payment-options.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { local } from '../../../environments/local';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  myRecaptcha: any;
  cartItems: any;
  cartPackageItems: any;
  isLoggedIn: boolean;
  user_id: any = localStorage.getItem('currentUserId') ? localStorage.getItem('currentUserId') : sessionStorage.getItem('currentUserId');
  path: any;
  images: any;
  packageImages: any;
  total: any;
  subTotal: any;
  tax: any;
  cartEmpty = false;
  userDetails: any;
  showSpinner: boolean;
  hasPromo: any;
  discount: any;
  Accept: any;
  error: any;
  success: any;
  selectedCountry: any;
  hide: any;
  newsletter = true;
  apiLoginID: string;
  clientKey: string;
  zeroTotal = false;

  constructor(private checkoutCart: DataService, private router: Router, public dialog: MatDialog, private titleService: Title) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(PaymentOptionsComponent, {
      width: '1250px',
      height: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit() {
    this.apiLoginID = local.apiLoginID;
    this.clientKey = local.clientKey;
    this.titleService.setTitle("Checkout - Backdrop Projections");
    localStorage.setItem('baseUrl', local.baseUrl);

    this.path = local.imageUrl + '/storage';


    if (sessionStorage.getItem('promo_code')) {
      this.hasPromo = true;
    }

    if (localStorage.getItem('currentUserId') || sessionStorage.getItem('currentUserId')) {
      this.isLoggedIn = true;
    }

    this.checkoutCart.getCartItems(this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.cartItems = data.success.cartItems;
          this.cartPackageItems = data.success.cartPackageItems;

          this.images = data.success.images;
          this.packageImages = data.success.packageImages;

          this.total = data.success.total;

          if (this.hasPromo) {
            this.total = this.total - Number(sessionStorage.getItem('discount'));
            this.discount = Number(sessionStorage.getItem('discount'));
            if (this.total < 0) {
              this.total = 0;
              this.zeroTotal = true;
            }
          }

          this.subTotal = data.success.subTotal;
          this.tax = data.success.tax;
          console.log(this.total);
          console.log(this.subTotal);
          console.log(this.tax);
        }

      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );

    this.checkoutCart.getUserDetails(this.user_id).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.userDetails = data.success.user;
          if (data.success.user[0].country === null || data.success.user[0].country === '') {
            this.selectedCountry = 'USA';
          } else {
            this.selectedCountry = data.success.user[0].country;
          }
        }

      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);
      }
    );
  }

  ngAfterViewInit() {
    if (!this.zeroTotal) {
      setTimeout(function () {
        console.log('loading');
        let script = document.createElement('script');
        if (local.production === true) {
          script.src = 'https://js.authorize.net/v3/AcceptUI.js';
        }
        else {
          script.src = 'https://jstest.authorize.net/v3/AcceptUI.js';
        }

        document.head.appendChild(script);
      }, 5000);

    }
  }
  checkout({ value, valid }: { value: any, valid: boolean }) {
    // alert(value['newsletter']);
    if (!valid) {
      console.log('not valid');
    } else {
      const addressTwo = value['address_field2'] ? value['address_field2'] : ' ';
      this.checkoutCart.updateUserDetails(this.user_id, value['first_name'], value['last_name'],
        value['country'], value['city'], value['state'], value['address_field1'] + ' ' + addressTwo,
        value['zip_code'], value['company_name'], value['phone'], value['newsletter']).subscribe(
          (data: any) => {
            console.log(data);
            if (data.success) {
              console.log(data);
              this.checkoutCart.getUserDetails(this.user_id).subscribe(
                (api: any) => {
                  if (api.success) {
                    console.log(api.success);
                    this.userDetails = api.success.user;
                  }
                },
                (error: any) => {
                  console.log(error);
                  this.router.navigate(['/error']);
                }
              );
            }

          },
          (error: any) => {
            console.log(error);
            this.router.navigate(['/error']);
          }

        );
    }
  }

  processOrder() {
    this.hide = true;
    this.checkoutCart.processOrder(this.user_id, sessionStorage.getItem('discount'),
      sessionStorage.getItem('promo_code_id'), this.tax).subscribe(
        (data: any) => {
          console.log(data);
          if (data.success) {
            console.log(data);
            setTimeout(() => {
              this.router.navigate(['/orderComplete']);
            }, 1500);

          }

        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
        }

      );
  }

  processPayment() {
    document.getElementById('save').click();
    this.showSpinner = true;
    // window.focus(); //force focus on the currenct window;
    // window.addEventListener('blur', function(e){
    //     if(document.activeElement == document.getElementById('AcceptUIContainer').firstChild)
    //     {
    //         alert('Focus Left Current Window and Moved to Iframe / Possible click!');
    //     }
    // });
    const check = setInterval(() => {
      this.checkoutCart.getPaymentStatus(this.user_id).subscribe(
        (data: any) => {
          if (data.success) {
            console.log(data.success);
            const test = document.getElementById('AcceptUIContainer');
            if (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1) {
              // alert('mobile');
              document.getElementById('AcceptUIContainer').style.left = '20%';
              document.getElementById('AcceptUIContainer').style.right = '20%';
              document.getElementById('AcceptUIContainer').style.top = '30%';
            } else {
              // alert('not mobile');
              document.getElementById('AcceptUIContainer').style.top = '64%';
            }
            const background = document.getElementById('AcceptUIBackground');
            if (test.className !== 'show') {
              this.hide = true;
            }

            if (sessionStorage.getItem('data') === null) {
              setTimeout(() => {
                if (test.className !== 'show' && data.success.status === null || data.success.status.transaction_id === null) {
                  location.reload();
                }
              }, 1000);
            }
            if (this.hide) {
              setTimeout(() => {
                this.checkoutCart.checkChargeToken().subscribe(
                  (checks: any) => {
                    clearInterval(check);
                    location.reload();
                    console.log(checks);
                    if (checks.error) {
                      console.log(checks.success);
                    }
                  },
                  (error: any) => {
                    // alert('error')
                    clearInterval(check);
                    location.reload();
                    console.log(error);
                    this.router.navigate(['/error']);
                    sessionStorage.removeItem('data');
                  }
                );
              }, 2 * 60 * 1000);
            }
            if (this.router.url !== '/checkout') {
              test.className = '';
              background.className = '';
              clearInterval(check);
              sessionStorage.removeItem('data');
            }
            if (data.success.status !== null) {
              if (data.success.status.transaction_id !== null && data.success.status.api_error_message === null) {
                this.showSpinner = false;
                console.log('Success');
                console.log(data.success.status);
                console.log(data.success.status.api_error_message);
                clearInterval(check);
                this.router.navigate(['/orderComplete']);
                setTimeout(() => {
                  location.reload();
                  sessionStorage.removeItem('data');
                }, 2000);
              } else {
                setTimeout(() => {
                  if (data.success.status.error_message !== null || data.success.status.api_error_message !== null) {
                    alert('Please try again later');
                  }

                  location.reload();
                  sessionStorage.removeItem('data');
                }, 5000);
              }
            }
          }
        },
        (error: any) => {
          console.log(error);
          this.router.navigate(['/error']);
          sessionStorage.removeItem('data');
        }
      );
    }, 1000);


    // setTimeout(() => {
    //   this.showSpinner = false;
    //   // this.showSuccess = true;
    // }, 2000);
  }



}
