import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { isDevMode } from '@angular/core';
import { local } from '../../../environments/local';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.css']
})
export class DownloadAppComponent implements OnInit {

mac: any;
windows: any;
path: any;

  constructor(private app: DataService, private router: Router, private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle("Download App - Backdrop Projections");


      this.path = local.imageUrl + '/storage';


    this.app.getApp().subscribe(
      (data: any) => {
        if (data.success) {
          console.log(data.success);
          this.windows = data.success.windows;
          this.mac = data.success.mac;
        }
      },
      (error: any) => {
        console.log(error);
        this.router.navigate(['/error']);

      }
    );
  }

}
